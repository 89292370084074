$(document).ready(function() {
	new WOW().init();
	$("#testimonial").owlCarousel({
		navigation: true, // Show next and prev buttons
		singleItem: true,
		autoPlay:true,
		stopOnHover:true,
		navigationText: ["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>"]
	});

});
